@import '~react-md/src/scss/react-md';

// // Any variable overrides. The following just changes the default theme to use teal and purple.
// $md-primary-color: $md-teal-500;
// $md-secondary-color: $md-purple-a-400;
// $md-light-theme: false; // setting to false in v2 since the default background is dark
$md-drawer-tablet-width: 360px;
$md-drawer-desktop-width: 360px;

.md-drawer--right {
  max-width: 400px !important;
  left: auto !important;
}

.text-fields {
  // The inline indicator expects an icon sized element
  &__inline-btn.md-btn {
    height: $md-font-icon-size;
    padding: 0;
    width: $md-font-icon-size;

    @media #{$md-desktop-media} {
      height: $md-font-icon-dense-size;
      width: $md-font-icon-dense-size;
    }
  }
}